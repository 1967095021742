import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import orangeMagnetPath from '../images/orange-magnet.png';
import StackedJobSearchForm from './forms/StackedJobSearchForm';
import { useMediumMediaQuery } from "../hooks/responsiveMediaQueries";
import '../css/Home.css'

function Home() {
  const isMedium = useMediumMediaQuery();

  return(
    <>
      <Row className="g-0 home-container">
        <Col md={{ span: 7, order: 1 }} xs={{ span: 12, order: 2 }} className={"d-flex vh-40-container vh-94-md-container vh-40-container align-items-center justify-content-center " + (isMedium ? "home-background-no-magnet" : "home-background-left-magnet")}>
          <Row >
            <Col lg={7} md={10} sm={12} className="pt-md-4 ms-md-4 text-center text-md-start">
              <h1 className="display-4 pb-4 fw-bold"><span className="primary-mw-text">Word of mouth hiring</span> <span className="tertiary-mw-text">meets local economies.</span></h1>
            </Col>
            <Col md={7} sm={12} className="ms-md-4 text-center text-md-start">
              <p className="lead fw-bold">Automated referral tracking, a local rewards marketplace, and a better way to hire with community. <br /><Link to='/employers'>Employers - learn more</Link></p>
            </Col>
            <Col className="d-flex flex-column ms-md-4 ms-lg-0 align-items-center align-items-md-start">
              <img alt="magnetworks" className="orange-magnet-img" src={orangeMagnetPath} />
            </Col>
          </Row>
        </Col>

        <Col md={{ span: 5, order: 2 }}  xs={{ span: 12, order: 1 }} className="d-flex flex-column vh-40-container vh-94-md-container right-half-abstract-magnet dark-background align-items-center justify-content-center">
          <StackedJobSearchForm />
        </Col>
      </Row>
    </>
  );
}

export default Home;